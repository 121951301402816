<template>
  <div class="wrapper">
    <div class="body" style="padding: 0">
      <!-- <Slider></Slider> -->
      <Banner />

      <div class="cas-header-wrapper">
        <!-- <div class="se-wrapper py-2 px-2">
          <div class="input-group"
            style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 50px;text-align: center;">
            <input @keyup="keyUpSearch" @keyup.enter="performSearch" type="text" class="form-control" id="search-input"
              name="keyword" placeholder="Search for casino games" aria-label="Search for games, leagues, events"
              v-model="to_search">
            <div @click="performSearch" class="input-group-prepend"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 50px;text-align: center;outline: 1px solid white;">
              <button style="background-color: var(--red);" class="btn btn-sm" type="button"><i
                  style="color: var(--grey);" class="bi bi-search mr-1"></i> Search</button>
            </div>
          </div>
        </div> -->
        <div class="casino-scroll" id="catsec">
          <div
            class="casino-col"
            v-for="(item, index) in categories"
            @click="getCasinoGames(item)"
            :class="isActive(item)"
            v-bind:key="getKey(index)"
          >
            <span>
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>

      <!-- allgamesdata -->
      <!-- -->

      <div
        class="row m-0 p-0"
        style="background-color: var(--background-color)"
      >
        <div
          v-show="searchgamesdata.length > 0"
          class="casino-headers"
          style=""
        >
          <span>Search Results</span>
        </div>

        <!-- new card design -->

        <div
          v-show="searchgamesdata.length > 0"
          class="row m-0 p-0 casino-row"
          v-for="(o, index) in searchgamesdata"
          v-bind:key="getKey(index)"
        >
          <div
            class="game-icon casino-card col-lg-3 col-md-3 col-sm-3 col-3 m-0 p-0"
          >
            <a>
              <img
                loading="lazy"
                :src="getImg(o.image)"
                alt="Avatar"
                class="game-card"
                data-toggle="modal"
                style="border: solid var(--light-gray) 1px"
                :data-target="'#searchCasinoGames' + o.game_id"
              />
            </a>
          </div>

          <div
            class="modal fade"
            :id="'searchCasinoGames' + o.game_id"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'searchCasinoGamesLabel' + o.game_id"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog" role="document">
              <div style="color: var(--icons-color)" class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title"
                    :id="'searchCasinoGamesLabel' + o.game_id"
                  >
                    {{ o.game_name }}
                  </h5>
                  <span
                    type="button"
                    class="close"
                    id="faso-close"
                    data-dismiss="modal"
                    >&times;</span
                  >
                </div>
                <div class="modal-body">
                  <img
                    loading="lazy"
                    style="
                      border-radius: 5px;
                      border: solid var(--light-gray) 1px;
                    "
                    :src="o.image"
                    alt="gameimg"
                    class="ww-100 mb-2"
                  />
                  <div class="text-center">
                    <router-link
                      data-dismiss="modal"
                      style="
                        background-color: var(--icons-color);
                        color: var(--white);
                      "
                      class="btn ww-100 mb-2"
                      :to="{
                        name: 'casinogames',
                        params: {
                          IsDemo: 0,
                          providerID: o.provider_id,
                          gameName: o.game_name,
                          gameID: o.game_id,
                          launchURL: o.launch_url,
                          launchType: o.launch_type,
                          launchParameters: o.launch_parameters,
                        },
                      }"
                    >
                      Play Now
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShimmerCasino v-if="isLoading"></ShimmerCasino>

      <NoData
        :title="$t('noGame')"
        :description="$t('noGameOnCategory')"
        v-if="!isLoading && !allgamesdata"
      />

      <div
        class="scroller"
        id="scroller"
        v-if="!isLoading && allgamesdata && allgamesdata.length > 0"
      >
        <div
          v-show="searchgamesdata.length == 0"
          class="casino-game"
          style="background-color: var(--background-color)"
        >
          <div
            style="text-align: center"
            v-for="(i, x) in allgamesdata"
            v-bind:key="getKey(x)"
          >
            <div class="game-icon">
              <div class="players">
                <div>
                  <div class="green"></div>
                  <span>{{ randomPlayers() }} players</span>
                </div>
              </div>
              <a @click="openSlideUp(i)">
                <img
                  loading="lazy"
                  :src="getImg(i.image)"
                  alt="Avatar"
                  data-toggle="modal"
                  :data-target="'#casinoGames' + i.game_id"
                />
              </a>
              <p style="color: var(--icons-color)">
                <span>{{ truncateFirstWord(i.game_name) }}</span>
                <img style="width: 15px" src="/menu/star.svg" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <ChopbetSlideUp
        :isOpen="isSlideUpOpen"
        @closeSlideup="closeSlideUp"
        :fullHeight="true"
      >
        <div class="chopslide">
          <div class="chopslide-heading" v-show="loading || isLoaded">
            <!-- <img src="/img/chopLifeLogo.svg" alt="Chopbet Logo" /> -->
            <ChopbetLogo />

            <div class="close-btn">
              <div class="control-button" v-if="isLoaded">
                <router-link to="/deposit-funds" class="special-font change">
                  <ChopbetButton :casino="true">
                    {{ $t("deposit") }}</ChopbetButton
                  >
                </router-link>
              </div>

              <!-- <img
                class="close_circle"
                @click="closeSlideUp"
                src="/img/close-circle.svg"
                alt="Chopbet close menu"
              /> -->
              <CloseIcon :color="closeIconColor" @click="closeSlideUp" />
              <!-- <CloseIcon :color="var(--deposit-fill)"/> -->
            </div>
          </div>
          <iframe
            allow="fullscreen"
            v-show="isLoaded"
            class="casino-iframe"
            v-bind:src="launchURL"
            allowfullscreen
            webkitallowfullscreen
          ></iframe>
          <div class="preview-game" v-show="!loading && !isLoaded">
            <div class="modal-img">
              <img
                v-if="previewGame"
                loading="lazy"
                v-bind:src="previewGame.image"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + previewGame.game_id"
              />
              <div class="preview-name">
                <p>{{ previewGame.game_name }}</p>
              </div>
            </div>
            <div class="control-button">
              <ChopbetButton
                @click="getLaunchUrl(previewGame)"
                :loading="loading"
              >
                {{ $t("playGames") }}</ChopbetButton
              >
            </div>
          </div>
          <div class="slide-loading" v-show="loading">
            <p>Loading...</p>
            <div class="progress-track">
              <div class="level" :style="{ width: 70 + '%' }"></div>
            </div>
          </div>
        </div>
      </ChopbetSlideUp>
    </div>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
import Vue from "vue";
import ShimmerCasino from "../../shimmer/ShimmerCasino.vue";
import ScrollLoader from "vue-scroll-loader";
import casino from "@/services/casino";
import Banner from "../../banner/Banner.vue";
import ChopbetSlideUp from "../../ui/ChopbetSlideUp.vue";
import providerServiceMap from "@/services/providerServiceMap";
import getProviderPayload from "@/utils/getProviderPayload";
import NoData from "../../ui/NoData.vue";
import ChopbetButton from "../../ui/ChopbetButton.vue";
import ChopbetLogo from "../../icons/ChopbetLogo.vue";
import CloseIcon from "../../icons/CloseIcon.vue";

Vue.use(ScrollLoader);
export default {
  name: "Casino",
  components: {
    // Slider,
    CloseIcon,
    Banner,
    ShimmerCasino,
    ChopbetButton,
    ChopbetSlideUp,
    NoData,
    ChopbetLogo,
  },
  data: function () {
    return {
      closeIconColor: "var(--deposit-fill)",
      isLoading: true,
      previewGame: {},
      isSlideUpOpen: false,
      activeOdd: 0,
      slideUp: "",
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      isLoaded: false,
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: "",
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          id: 9,
          title: "Popular",
        },
        {
          id: 4,
          title: "Slots",
        },
        {
          id: 17,
          title: "Classic Casino",
        },
        {
          id: 8,
          title: "Table Games",
        },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        id: 1,
        name: "Crash",
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
    };
  },

  mounted: function () {
    this.$store.dispatch("setCurrentPage", "casino");

    this.getCasinoGames(this.casinoCategoryActive);

    // this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {
        if (x.key == key) {
          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {
        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();
    // var touchableElement = document.getElementById("scroller");
    // touchableElement.addEventListener(
    //   "touchstart",
    //   function (event) {
    //     vm.touchstartX = event.changedTouches[0].screenX;
    //     vm.touchstartY = event.changedTouches[0].screenY;
    //   },
    //   false
    // );

    // touchableElement.addEventListener(
    //   "touchend",
    //   function (event) {
    //     vm.touchendX = event.changedTouches[0].screenX;
    //     vm.touchendY = event.changedTouches[0].screenY;
    //     vm.handleGesture();
    //   },
    //   false
    // );
  },

  methods: {
    randomPlayers() {
      // Generate a random number between 50 and 2000
      return Math.floor(Math.random() * (2000 - 50 + 1)) + 50;
    },
    openSlideUp(game) {
      this.previewGame = game;
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isLoaded = false;
      this.isLoading = false;
      this.isSlideUpOpen = false;
    },
    truncateFirstWord(gameName) {
      const firstWord = gameName.split(" ")[0];

      return firstWord.length > 7
        ? firstWord.substring(0, 7) + "..."
        : firstWord;
    },
    handleGesture: function () {
      var scrollLeft = document.getElementById("catsec").scrollLeft;
      if (
        this.touchendX < this.touchstartX &&
        this.touchstartX - this.touchendX > 125
      ) {
        console.log("Swiped Left");
        console.log("start: " + this.touchstartX + "end: " + this.touchendX);
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0;
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index);
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById("catsec").scrollLeft = scrollLeft + 50;
        }
      }

      if (
        this.touchendX > this.touchstartX &&
        this.touchendX - this.touchstartX > 125
      ) {
        console.log("Swiped Right");

        console.log("start: " + this.touchstartX + "end: " + this.touchendX);
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if (index - 1 > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[index - 1];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById("catsec").scrollLeft = scrollLeft - 50;
        }
      }

      if (this.touchendY < this.touchstartY) {
        console.log("Swiped Up");
      }

      if (this.touchendY > this.touchstartY) {
        console.log("Swiped Down");
      }

      if (this.touchendY === this.touchstartY) {
        console.log("Tap");
      }
    },
    isActive: function (item) {
      if (item.id == this.casinoCategoryActive.id) {
        return " active";
      } else {
        return "";
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add("d-none");
    },
    getLaunchUrl(data) {
      if (!this.profile) {
        this.setError(this.$t("pleaseLoginProceed"));
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      // this.allgamesdata = [];
      // this.allgames = [];
      this.loading = true;

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";
      let payload = getProviderPayload(
        providerId,
        accountID,
        gameId,
        gameName,
        device_type
      );
      if (!payload) {
        this.loading = false;
        return;
      }
      return this.launchGame(payload);
    },
    getCasinoGames: function (activeCategory) {
      var vm = this;

      vm.isLoading = true;
      vm.casinoCategoryActive = activeCategory;

      var path =
        "/games?page=" +
        this.currentPage +
        "&per_page=" +
        this.perPage +
        "&category_id=" +
        activeCategory.id;

      casino.get(path).then((resp) => {
        vm.allgamesdata = resp.data.data;
        vm.allgames = vm.allgamesdata;
        // console.log(
        //   "allgames --> " + JSON.stringify(vm.allgames, undefined, 2)
        // );
        this.isLoading = false;
        window.scrollTo(0, 0);
      });
    },
    launchGame: function (payload) {
      var vm = this;
      var providerId = payload.providerId;
      var service = providerServiceMap[providerId];
      if (!service) {
        this.loading = false;
        console.error("Invalid providerId:", providerId);
        return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL);
          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }
          console.log(
            "-----------------------------------------------",
            vm.launchURL
          );
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          this.loading = false;
          return vm.launchURL;
        })
        .catch((error) => {
          this.loading = false;
          this.closeSlideUp();
          console.error("Error launching game:", error);
        });
    },
    performSearch: function () {
      var keyword = this.to_search;
      var games = this.allgames;
      console.log(keyword);
      var results = games.filter(function (el) {
        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null
          ? false
          : game_name.match(keyword.toLowerCase()).length > 0;
      });

      console.log(
        "search results " + JSON.stringify(this.searchgamesdata, undefined, 2)
      );

      if (results.length > 0 && keyword !== "") {
        this.searchgamesdata = results;
      } else {
        this.searchgamesdata = [];
      }
    },
    keyUpSearch: function () {
      if (this.to_search == "") {
        this.searchgamesdata = [];
      }
    },
    loadPage: function (launch_url) {
      window.open(launch_url, "_blank");
    },
    getImg: function (img) {
      return img.replaceAll("/rec/325/", "/square/200/");
    },
    showSlides: function () {
      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {
        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      if (slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";
      }

      setTimeout(function () {
        vm.showSlides();
      }, 2000); // Change image every 2 seconds
    },

    getLaunchURL: function (launch_url) {
      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    getCategories: function () {
      var vm = this;

      var path = "/category";

      casino
        .get(path)
        .then((res) => {
          vm.categories = res.data;
        })
        .catch((err) => {
          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
  },

  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    allgamesdata() {
      console.log(this.allgamesdata, "allgamesdataallgamesdata");
    },
  },
};
</script>
