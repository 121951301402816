const getProviderPayload = (providerId, accountID, gameId, gameName, device_type) => {
    // Define a base payload
    let basePayload = {
      account_id: accountID,
      game_id: String(gameId),
      game_name: gameName,
      device_type: String(device_type),
      lobby_url: window.location.origin + "/casino",
      return_url: window.location.origin + "/casino",
      providerId: providerId,
    };
  
    // Customize payload based on providerId
    switch (providerId) {
      case parseInt(process.env.VUE_APP_PROVIDER_ID_PRAG):
        return { ...basePayload };
      case parseInt(process.env.VUE_APP_PROVIDER_ID_STP):
        return { ...basePayload, game_id: parseInt(gameId), account_id: parseInt(accountID) };
      case parseInt(process.env.VUE_APP_PROVIDER_ID_SMART):
        return { ...basePayload };
      case parseInt(process.env.VUE_APP_PROVIDER_ID_ELBET):
      case parseInt(process.env.VUE_APP_PROVIDER_ID_JETSAFI):
      case parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE):
        return { ...basePayload };
      case parseInt(process.env.VUE_APP_PROVIDER_ID_BOOMING):
      case parseInt(process.env.VUE_APP_PROVIDER_ID_SHACKSEVO):
        return { ...basePayload, demo: 0, device: `Device ${device_type}` };
      default:
        console.error("Invalid providerId:", providerId);
        return null; // Return null if providerId is invalid
    }
  };
  
export default getProviderPayload;