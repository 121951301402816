<template>
  <div class="slider-container hero-container">
    <div class="image-slider">
      <transition :name="transitionName">
        <img
          v-if="currentImage"
          :src="currentImage"
          :key="currentImage"
          class="slider-image"
          alt="Sliding Image"
        />
      </transition>
    </div>
    <div class="text_wrap">
      <img :src="bannerShape" alt="" />
      <div class="text_slide_wrap">
        <div class="slider-text" v-for="text in texts" :key="text">
          <p>{{ currentText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        "/img/banners/fr-banner1.webp",
        "/img/banners/fr-banner2.webp",
        "/img/banners/fr-banner3.webp",
        "/img/banners/fr-banner4.webp",
        "/img/banners/fr-banner5.webp",
        "/img/banners/fr-banner6.webp",
      ],
      currentImageIndex: 0,
      transitionName: "slide-left",
      texts: [
        `${this.$t("textPlayEverywhere")}`,
        `${this.$t("feelThrill")}`,
        `${this.$t("scoreBigWinBig")}`,
        `${this.$t("unleashThePowerOfYourLuck")}`,
      ],
      currentTextIndex: 0,
      textTransitionName: "slide-right",
      bannerShape: "/img/bannerShape.png",
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },

    currentText() {
      return this.texts[this.currentTextIndex];
    },
  },
  mounted() {
    this.startSlider();
  },
  methods: {
    startSlider() {
      setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
        this.transitionName = "slide-left";
        this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.hero-container {
  background: #cc1717;
  position: relative;
}
.text_wrap {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  z-index: 20;
}
.text_wrap img {
  width: 100%;
  height: 100%;
  margin-left: -10px;
  /* object-fit: cover; */
  /* outline: 1px solid red; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  /* background-color: red; */
  /* outline: 1px solid red; */
  /* border-radius: 10px; */
}
.slider-container {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.image-slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  /* outline: 4px solid red; */
  /* background-color: red; */
  /* border-radius: 10px; */
}

/* Vue Transition Classes */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 1s ease;
}

.slide-left-enter {
  transform: translateX(-100%);
}
.slide-left-leave-to {
  transform: translateX(100%);
}

/* Vue Transitions for Text */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 1s ease;
}

/* .slide-right-enter {
  transform: translateX(100%);
}
.slide-right-leave-to {
  transform: translateX(-100%);
} */
.text_slide_wrap {
  /* outline: 1px solid red; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  /* height: 50px; */
}

.slider-text {
  min-width: 100px;
  height: 100%;
  /* margin-right: 0;
  margin-left: auto; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
p {
  font-size: 12px;
  max-width: 90%;
  text-align: right;
  font-family: "Game Station";
  font-weight: 400;
  padding-left: 20px;
}
</style>
